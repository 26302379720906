import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2b99829a = () => interopDefault(import('../src/pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _81027a9c = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _33cc4b25 = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _31827248 = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _560e82d7 = () => interopDefault(import('../src/pages/receipt.vue' /* webpackChunkName: "pages/receipt" */))
const _a9ac254e = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _1071a8a8 = () => interopDefault(import('../src/pages/search-booking/index.vue' /* webpackChunkName: "pages/search-booking/index" */))
const _8e8cdc9a = () => interopDefault(import('../src/pages/select-extras.vue' /* webpackChunkName: "pages/select-extras" */))
const _78911365 = () => interopDefault(import('../src/pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _6b5e04a2 = () => interopDefault(import('../src/pages/unsupported-browser.vue' /* webpackChunkName: "pages/unsupported-browser" */))
const _13fe63fa = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _64092cf7 = () => interopDefault(import('../src/pages/manage-booking/modify.vue' /* webpackChunkName: "pages/manage-booking/modify" */))
const _4c05a26a = () => interopDefault(import('../src/pages/manage-booking/receipt.vue' /* webpackChunkName: "pages/manage-booking/receipt" */))
const _3382927b = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _760c6660 = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _00099b9e = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cookie-policy",
    component: _2b99829a,
    name: "cookie-policy"
  }, {
    path: "/maintenance",
    component: _81027a9c,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _33cc4b25,
    name: "manage-booking"
  }, {
    path: "/privacy-policy",
    component: _31827248,
    name: "privacy-policy"
  }, {
    path: "/receipt",
    component: _560e82d7,
    name: "receipt"
  }, {
    path: "/search",
    component: _a9ac254e,
    name: "search"
  }, {
    path: "/search-booking",
    component: _1071a8a8,
    name: "search-booking"
  }, {
    path: "/select-extras",
    component: _8e8cdc9a,
    name: "select-extras"
  }, {
    path: "/terms-and-conditions",
    component: _78911365,
    name: "terms-and-conditions"
  }, {
    path: "/unsupported-browser",
    component: _6b5e04a2,
    name: "unsupported-browser"
  }, {
    path: "/manage-booking/cancel",
    component: _13fe63fa,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/modify",
    component: _64092cf7,
    name: "manage-booking-modify"
  }, {
    path: "/manage-booking/receipt",
    component: _4c05a26a,
    name: "manage-booking-receipt"
  }, {
    path: "/manage-booking/update-details",
    component: _3382927b,
    name: "manage-booking-update-details"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _760c6660,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _00099b9e,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
