
// Packages
import { defineComponent } from 'vue';

// Types
import VueI18n from 'vue-i18n/types';
import { MetaInfo } from 'vue-meta';
import { v4 as uuidv4 } from 'uuid';

// Helpers
import { getAppVariable } from '@white-label-helper/get-app-variable';
import multiBasket from '@white-label-helper/mixin-multi-basket';

// Constants
import {
  NAMED_ROUTES,
  ROUTE_NAMES,
} from '@white-label-configuration/constants';

// Store
import { mapActions } from 'pinia';
import { useDeepLink } from '@white-label-store/deep-link';
import { readItems } from '@white-label-store/cart';
import {
  commitContinueClicked,
  commitShowBasketSlideOver,
} from '@white-label-store/multi-basket';

// Components
import {
  EcomFooter,
  Basket,
  EcommerceNavbar,
  ModalContainer
} from 'ui-shared-components';

// This logic is to generate token if its not exists in store before app loaded
let initToken = '';
if (process.client) {
  const VuexDOMString = localStorage.getItem('vuex') as string;
  const storeToken = JSON.parse(VuexDOMString)?.cart?.token;
  if (!storeToken) {
    initToken = (localStorage.getItem('initToken') as string) || uuidv4();
    localStorage.setItem('initToken', initToken);
  }
}

export default defineComponent({
  components: {
    EcomFooter,
    EcommerceNavbar,
    ModalContainer,
    Basket,
  },
  mixins: [multiBasket],

  head(): MetaInfo {
    return {
      title: this.createTabTitle,
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
    };
  },
  computed: {
    cartItems(): ReturnType<typeof readItems> {
      return readItems(this.$store);
    },
    getRouteName(): string {
      return this.$route.name || '';
    },

    getPageName(): VueI18n.TranslateResult {
      return this.$t(`pages.titles.${this.getRouteName}`);
    },

    createTabTitle(): string {
      return `${this.getPageName} | ${getAppVariable('partner_name')}`;
    },

    displayFooter(): boolean {
      return !this.$route?.path.includes(NAMED_ROUTES.maintenance);
    },

  },

  created() {
    // If pre init token created fill it in store
    if (!process.client) {
      return;
    }
    if (initToken) {
      this.$store.commit('cart/storeToken', initToken);
    }
  },
  mounted() {
    const branch = process.env.NUXT_ENV_CURRENT_BRANCH;
    if (branch === 'dev' || branch === 'master') {
      // eslint-disable-next-line no-console
      console.log(`Version 0.${Number(process.env.NUXT_ENV_JOB_ID)}`);
    }
    if (
      this.$route.query?.discount &&
      typeof this.$route.query.discount === 'string'
    ) {
      this.updateDiscount(this.$route.query.discount);
    }
  },

  methods: {
    ...mapActions(useDeepLink, ['updateDiscount']),
    async onContinueHandler(): void {
      commitContinueClicked(this.$store, false);
      this.$nextTick(() => {
        commitContinueClicked(this.$store, true);
        commitShowBasketSlideOver(this.$store, false);
      })

    },
  },
});
