// eslint-disable-next-line
// @ts-nocheck
import { ActionContext } from 'vuex';
import Vue from 'vue';
import { getStoreAccessors } from 'typesafe-vuex';
import { RootState } from '@white-label-types/stores';
import type {
  ParkingFormData,
  LoungeFormData,
} from '@white-label-types/search-box';
import type { SearchProducts } from '@white-label-types/parking-booking';
import {
  PARKING_CATEGORY_TYPES,
  PARKING_SORT_OPTIONS,
} from '@white-label-configuration/constants';
type Playbook = { order: number; product_id: number };
type Playbooks = {
  featured: Playbook[];
  boosted: Playbook[];
  bottom: Playbook[];
};

export type State = {
  searchResults: SearchProducts[];
  searchExtrasLounges: SearchProducts[];
  formData: ParkingFormData | LoungeFormData | null;
  timestamp: Date | string | null;
  yourSearchProductId: number | null;
  searchToken?: string;
  playbooks?: Playbooks;
  activeFilterCategoryType?: string;
  activeSort?: string;
  searchErrorApi: boolean;
};

type SearchResultsContext = ActionContext<State, RootState>;

const searchResultsState = (): State => ({
  searchResults: [],
  searchExtrasLounges: [],
  formData: {
    entryDate: '',
    entryTime: null,
    exitDate: '',
    exitTime: null,
  },
  timestamp: null,
  searchErrorApi:false,
  searchToken: '',
  playbooks: {
    featured: [],
    boosted: [],
    bottom: [],
  },
  yourSearchProductId: null,
  activeFilterCategoryType: PARKING_CATEGORY_TYPES.ALL,
  activeSort: 'recomended',
});

const getters = {
  searchResults: (state: State): State['searchResults'] => state.searchResults,
  searchExtrasLounges: (state: State): State['searchExtrasLounges'] => state.searchExtrasLounges,
  formData: (state: State): State['formData'] => state.formData,
  timestamp: (state: State): State['timestamp'] => state.timestamp,
  searchErrorApi: (state: State): State['searchErrorApi'] => state.searchErrorApi,
  yourSearchProductId: (state: State): State['yourSearchProductId'] =>
    state.yourSearchProductId,
  sortedSearchResults: (state: State): State['searchResults'] => {
    const filteredSearchResults =
      state.activeFilterCategoryType === PARKING_CATEGORY_TYPES.ALL
        ? [...state.searchResults]
        : // @ts-expect-error product_option doesnt not exist
          state.searchResults.filter(
            (result) =>
              result.product_option.code === state.activeFilterCategoryType
          );

    if (state.activeSort === PARKING_SORT_OPTIONS.PRICE) {
      return filteredSearchResults.sort((first, second) => {
        // @ts-expect-error productOptions doesnt not exist
        const firstPrice =
          first.productOptions[0].totals.channel_based.total_discounted;
        // @ts-expect-error name doesnt not exist
        const firstName = first.name;
        // @ts-expect-error productOptions doesnt not exist
        const secondPrice =
          second.productOptions[0].totals.channel_based.total_discounted;
        // @ts-expect-error name doesnt not exist
        const secondName = second.name;

        if (firstPrice > secondPrice) {
          return 1;
        }
        if (firstPrice < secondPrice) {
          return -1;
        }
        if (firstPrice === secondPrice) {
          if (firstName > secondName) {
            return 1;
          }
          if (firstName < secondName) {
            return -1;
          }
        }
        return 0;
      });
    }

    if (state.activeSort === PARKING_SORT_OPTIONS.DISTANCE) {
      return filteredSearchResults.sort((first, second) => {
        // @ts-expect-error transfer doesnt not exist
        const firstDistance = Number(first.transfer.distance);
        // @ts-expect-error name doesnt not exist
        const firstName = first.name;
        // @ts-expect-error transfer doesnt not exist
        const secondDistance = Number(second.transfer.distance);
        // @ts-expect-error name doesnt not exist
        const secondName = second.name;

        if (firstDistance > secondDistance) {
          return 1;
        }
        if (firstDistance < secondDistance) {
          return -1;
        }
        if (firstDistance === secondDistance) {
          if (firstName > secondName) {
            return 1;
          }
          if (firstName < secondName) {
            return -1;
          }
        }
        return 0;
      });
    }

    const yourSearchProduct = filteredSearchResults.find(
      (item) => item.id === state.yourSearchProductId
    );

    const featured = filteredSearchResults
      .filter((item) =>
        state.playbooks?.featured.some(
          (product) =>
            product.product_id === item.id &&
            product.product_id !== yourSearchProduct?.id
        )
      )
      .sort((first, second) => {
        const firstOptions = state.playbooks?.featured.find(
          (product) => product.product_id === first.id
        );
        const secondOptions = state.playbooks?.featured.find(
          (product) => product.product_id === second.id
        );

        if (firstOptions && secondOptions) {
          return firstOptions.order > secondOptions.order ? 1 : -1;
        }
        return 0;
      });

    const boosted = filteredSearchResults
      .filter((item) =>
        state.playbooks?.boosted.some(
          (product) =>
            product.product_id === item.id &&
            product.product_id !== yourSearchProduct?.id
        )
      )
      .sort((first, second) => {
        const firstOptions = state.playbooks?.boosted.find(
          (product) => product.product_id === first.id
        );
        const secondOptions = state.playbooks?.boosted.find(
          (product) => product.product_id === second.id
        );

        if (firstOptions && secondOptions) {
          return firstOptions.order > secondOptions.order ? 1 : -1;
        }
        return 0;
      });

    const bottom = filteredSearchResults
      .filter((item) =>
        state.playbooks?.bottom.some(
          (product) =>
            product.product_id === item.id &&
            product.product_id !== yourSearchProduct?.id
        )
      )
      .sort((first, second) => {
        const firstOptions = state.playbooks?.bottom.find(
          (product) => product.product_id === first.id
        );
        const secondOptions = state.playbooks?.bottom.find(
          (product) => product.product_id === second.id
        );

        if (firstOptions && secondOptions) {
          return firstOptions.order > secondOptions.order ? 1 : -1;
        }
        return 0;
      });

    const others = filteredSearchResults
      .filter(
        (item) =>
          !featured.some((product) => product.id === item.id) &&
          !boosted.some((product) => product.id === item.id) &&
          !bottom.some((product) => product.id === item.id) &&
          yourSearchProduct?.id !== item.id
      )
      .sort((first, second) => {
        // @ts-expect-error productOptions doesnt not exist
        const firstPrice =
          first.productOptions[0].totals.channel_based.total_discounted;
        // @ts-expect-error name doesnt not exist
        const firstName = first.name;
        // @ts-expect-error productOptions doesnt not exist
        const secondPrice =
          second.productOptions[0].totals.channel_based.total_discounted;
        // @ts-expect-error name doesnt not exist
        const secondName = second.name;

        if (firstPrice > secondPrice) {
          return 1;
        }
        if (firstPrice < secondPrice) {
          return -1;
        }
        if (firstPrice === secondPrice) {
          if (firstName > secondName) {
            return 1;
          }
          if (firstName < secondName) {
            return -1;
          }
        }
        return 0;
      });

    return [
      // If a product is available which matches the product id from query string, show this at the top of results
      ...(yourSearchProduct ? [yourSearchProduct] : []),
      // Otherwise, featured products show at the top of the results. Order 1 = First
      ...featured,
      // Boosted products will be listed after featured, and ordered based on BE
      ...boosted,
      ...others,
      // Bottom products will be listed last, and ordered based on BE
      ...bottom,
    ];
  },
  parkingsSearchResultsEntryDate: (state: State): string | null | undefined =>
    state.formData?.entryDate,
  parkingsSearchResultsEntryTime: (state: State): string | undefined =>
    state.formData?.entryTime?.value,
  parkingsSearchResultsExitDate: (state: State): string | null | undefined =>
    state.formData?.exitDate,
  parkingsSearchResultsExitTime: (state: State): string | undefined =>
    state.formData?.exitTime?.value,
  searchToken: (state: State): State['searchToken'] => state.searchToken,
  featuredProducts: (state: State): Playbook[] | undefined =>
    state.playbooks?.featured,
};

const mutations = {
  storeSearchResults(
    state: State,
    payload: {
      searchResults: State['searchResults'];
      formData: Partial<FormData>;
      timestamp: Date;
      yourSearchProductId: number | null;
    }
  ) {
    state.searchResults = payload.searchResults;
    // @ts-expect-error - At the moment the types are too inconsistent to get this work
    state.formData = { ...payload.formData };
    state.timestamp = payload.timestamp;
    state.yourSearchProductId = payload.yourSearchProductId;
  },

  storeExtrasSearchResults(
    state: State,
    payload: {
      searchResults: State['searchExtrasLounges'];
      formData: Partial<FormData>;
      timestamp: Date;
    }
  ) {
    state.searchExtrasLounges = payload.searchResults;
    // @ts-expect-error - At the moment the types are too inconsistent to get this work
    state.formData = { ...payload.formData };
    state.timestamp = payload.timestamp;
  },

  clearSearchResults(state: State) {
    state.searchResults = [];
    state.formData = null;
    state.timestamp = null;
    state.yourSearchProductId = null;
  },

  storeSearchToken(state: State, payload: string) {
    state.searchToken = payload;
  },

  storePlaybooks(state: State, payload: Playbooks) {
    state.playbooks = payload;
  },

  storeActiveSort(state: State, payload: string) {
    state.activeSort = payload;
  },
  storeSearchErrorApi(state: State, payload: boolean) {
    state.searchErrorApi = payload;
  },
  storeActiveFilterCategoryType(state: State, payload: string) {
    state.activeFilterCategoryType = payload;
  },

  setCartId(state: State, payload: { id: number; cart_item_id: string; isTravelExtras: boolean }) {
    const searchResults = payload.isTravelExtras ? state.searchExtrasLounges : state.searchResults;
    const productIndex = searchResults.findIndex(
      (item) => item.id === payload.id
    );
    if (productIndex >= 0) {
      Vue.set(
        searchResults[productIndex],
        'cart_item_id',
        payload.cart_item_id
      );
    }
  },

  removeCartItemId(
    state: State,
    payload: { id: number; cart_item_id: string }
  ) {
    const productIndex = state.searchResults.findIndex(
      (item) => item?.cart_item_id === payload.cart_item_id
    );
    if (productIndex >= 0) {
      Vue.delete(state.searchResults[productIndex], 'cart_item_id');
    }
  },
};

const actions = {
  convertSearchResults({ commit }: SearchResultsContext, payload: State) {
    return new Promise<void>((resolve) => {
      const { searchResults } = payload;
      if (!searchResults.length) {
        commit('storeSearchResults', {
          searchResults: [],
          formData: payload.formData,
          timestamp: payload.timestamp,
          yourSearchProductId: payload.yourSearchProductId,
        });
        resolve();
      }

      commit('storeSearchResults', {
        searchResults,
        formData: payload.formData,
        timestamp: payload.timestamp,
        yourSearchProductId: payload.yourSearchProductId,
      });
      resolve();
    });
  },
  convertExtrasSearchResults({ commit }: SearchResultsContext, payload: State) {
    return new Promise<void>((resolve) => {
      const { searchResults } = payload;
      if (!searchResults.length) {
        commit('storeExtrasSearchResults', {
          searchResults: [],
          formData: payload.formData,
          timestamp: payload.timestamp,
        });
        resolve();
      }

      commit('storeExtrasSearchResults', {
        searchResults,
        formData: payload.formData,
        timestamp: payload.timestamp,
      });
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state: searchResultsState,
  getters,
  mutations,
  actions,
};

const { commit, read, dispatch } = getStoreAccessors<State, RootState>(
  'searchResults'
);
export const readSearchResults = read(getters.searchResults);
export const readSearchExtrasLounges = read(getters.searchExtrasLounges);
export const readFormData = read(getters.formData);
export const readTimestamp = read(getters.timestamp);
export const readSortedSearchResults = read(getters.sortedSearchResults);
export const readSearchErrorApi = read(getters.searchErrorApi);
export const readYourSearchProductId = read(getters.yourSearchProductId);
export const readParkingsSearchResultsEntryDate = read(
  getters.parkingsSearchResultsEntryDate
);
export const readParkingsSearchResultsEntryTime = read(
  getters.parkingsSearchResultsEntryTime
);
export const readParkingsSearchResultsExitDate = read(
  getters.parkingsSearchResultsExitDate
);
export const readParkingsSearchResultsExitTime = read(
  getters.parkingsSearchResultsExitTime
);
export const readSearchToken = read(getters.searchToken);
export const readFeaturedProducts = read(getters.featuredProducts);

export const commitStoreSearchResults = commit(mutations.storeSearchResults);
export const commitClearSearchResults = commit(mutations.clearSearchResults);
export const commitStoreSearchToken = commit(mutations.storeSearchToken);
export const commitStorePlaybooks = commit(mutations.storePlaybooks);
export const commitStoreActiveSort = commit(mutations.storeActiveSort);
export const commitStoreSearchErrorApi = commit(mutations.storeSearchErrorApi);
export const commitStoreActiveFilterCategoryType = commit(
  mutations.storeActiveFilterCategoryType
);
export const commitRemoveCartItemId = commit(mutations.removeCartItemId);

export const dispatchConvertSearchResults = dispatch(
  actions.convertSearchResults
);
export const dispatchConvertExtrasSearchResults = dispatch(
  actions.convertExtrasSearchResults
);
